// import 'swiper/swiper-bundle.css';
import Swiper, { Autoplay, EffectCards, EffectFade, Navigation, Pagination, Thumbs,  } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs, EffectFade, EffectCards, Autoplay]);

const Heroswiper = new Swiper( '.swiper.hero-swiper', {
	// Optional parameters
  slidesPerView: 1,
  spaceBetween: 30,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
	loop: true,
	speed: 1500,
	effect: 'fade',
	fadeEffect: {
	  crossFade: true
	},
	// If we need pagination


	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: '.hero__swiper-pagination',
		clickable: true
	},

} );

let nextSlides = document.querySelectorAll('.hero__next-slide');

const heroVideo = document.querySelector('#heroVideo');

function playVideo(){   //pause and play video
	var removedElement = jQuery("#heroVideo").detach();
	var targetContainer = jQuery(".hero__slide-background.video");
	
	// Remove any existing elements within the target container
	targetContainer.children().remove();
	
	// Append the removed element to the target container
	targetContainer.append(removedElement);
	
}
// Event listener for slide change
Heroswiper.on('slideChange', function() {
	playVideo();
});



if(nextSlides){
	nextSlides.forEach(slide => {
		slide.addEventListener('click', playVideo);
		slide.addEventListener('click', () => {
			let id = parseInt(slide.dataset.slide);
			let current = parseInt(Heroswiper.activeIndex);
		
	
			if (current + id <= 4){
				if(id == 1){
					Heroswiper.slideNext();
				}else if(id == 2){
					Heroswiper.slideTo(current + 2);
					
				}else if(id == 3){
			
					Heroswiper.slideTo(current + 3);
				}
			}else{
				if(current + id == 5){
					Heroswiper.slideTo(1);
				}else if(current + id == 6){
					Heroswiper.slideTo(current + id - 4);
					
				}else if(current + id == 7){
					Heroswiper.slideTo(current + id - 4);
				}
			}
		
		})
	})
}


const postSwiper = new Swiper( '.swiper.custom-slider__slider--posts', {
	// Optional parameters
  slidesPerView: 1,
  spaceBetween: 30,
	loop: true,

	// If we need pagination
	pagination: {
		el: '.custom-slider__pagination',
		clickable: true
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},

} );

const videosSwiper1 = new Swiper( '.swiper.swiper-videos', {
	// Optional parameters
  slidesPerView: "auto",
  spaceBetween: 30,
  slidesOffsetAfter: 30,

	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
		slidesOffsetAfter: 0,
		clickable: true
	},
	breakpoints: {
		500:{
		
			slidesOffsetAfter: 60,
		},
	},
} );


const customSwiperAutom = new Swiper( '.swiper.custom-slider__slider-automatyzacja', {
	// Optional parameters
  slidesPerView: 1,
  spaceBetween: 30,
loop: true,

	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},

} );
const customSwiperCarier = new Swiper( '.swiper.custom-slider__slider-kariera', {
	// Optional parameters
  slidesPerView: 1,
  spaceBetween: 30,
	loop: true,

	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},

} );
const imageSwiperCarier = new Swiper('.swiper.carier-slider__swiper', {
	// Optional parameters
	slidesPerView: 2.6,
	spaceBetween: 30,
	loop: true,
	autoplay: true,
	centeredSlides: true,
  
	// If we need pagination
	pagination: {
	  el: '.swiper-pagination',
	  clickable: true
	},
  
	// Navigation arrows
	navigation: {
	  nextEl: '.swiper-button-next',
	  prevEl: '.swiper-button-prev',
	},

	
  
	// Responsive breakpoints
  });
  

const madSwiper = new Swiper( '.swiper.mad__swiper', {
	// Optional parameters
  slidesPerView: "auto",
  spaceBetween: 30,
  slidesOffsetAfter: 30,
	// loop: true,
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		500:{
			slidesOffsetAfter: 80,
		},
	}

} );




var galleryThumbs = new Swiper('.gallery-thumbs', {
	direction: 'vertical', // set to 'vertical' if you want to display the thumbnails in columns instead of rows
	slidesPerView: 8, // set to the number of thumbnails you want to display in one column
	// slidesPerColumn: 4, // set to the number of rows you want to use to display the thumbnails
	spaceBetween: 10,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,

  });

  var galleryTop = new Swiper('.gallery-top', {
//   loopedSlides: 1,
  spaceBetween: 0,
  slidesPerView: 1,
//   centeredSlides: true,
//   allowTouchMove:false,
  effect: 'fade',
  speed: 1000,
  noSwipingClass: 'swiper-slide',
  fadeEffect: {
    crossFade: true
  },

  // USING THE THUMBS COMPONENT
  thumbs: {
    swiper: galleryThumbs
  }
  });
 


// const opinionSwiper = new Swiper( '.swiper.opinion-slider__slider', {
// 	effect: "cards",
// 	// spaceBetween: 60,
// 	cardsEffect: {
// 		perSlideOffset: 0,
// 		perSlideRotate: 0,
// 		slideShadows: false,
// 	  },
// 	centeredSlides: true,
// 	pagination: {
// 	  el: '.swiper-pagination',
// 	  clickable: true,
// 	},
// 	// Navigation arrows
// 	navigation: {
// 		nextEl: '.swiper-button-next',
// 		prevEl: '.swiper-button-prev',
// 	},
// 	on: {
// 		slideChange: function () {
// 		  var nextNextSlide = this.slides.eq(this.activeIndex + 2);
// 		  nextNextSlide.addClass('slide-third');
// 		},
// 	  },

// } );

const opinionSwiper = new Swiper( '.swiper.opinion-slider__slider', {
	
	spaceBetween: -300,
	centeredSlides: true,
	slidesPerView: "auto",
	noSwiping: true,
	noSwipingClass: 'swiper-slide',
	updateOnWindowResize: 1,
	pagination: {
	  el: '.swiper-pagination',
	  clickable: true,
	},
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on: {
		init: function () {
			// this.slideTo(0);
			var nextNextSlide = this.slides.eq(this.activeIndex + 2);
			nextNextSlide.addClass('slide-third');
		  },
		slideChange: function () {
		  var nextSlide = this.slides.eq(this.activeIndex + 1);
		  var nextNextSlide = this.slides.eq(this.activeIndex + 2);
		  if(nextSlide.hasClass('slide-third')){
			  nextSlide.removeClass('slide-third');
			}
			nextNextSlide.addClass('slide-third');
		},
	  },

} );

if(document.querySelector('.swiper.opinion-slider__slider')){
	opinionSwiper();
}



const productSwiper = new Swiper( '.swiper.recomended-product__swiper', {
	slidesPerView: 1.3,
	
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	
	updateOnWindowResize: 1,
	breakpoints: {
		350:{
			slidesPerView: 1.2,
		},
		400:{
			slidesPerView: 1.4,
		},
		480:{
			slidesPerView: 1.8,
		},
		595:{
			slidesPerView: 2.2,
			spaceBetween: 30,
		},
		700: {
			slidesPerView: 2.6,
		  },
		840: {
			slidesPerView: 2,
		  },
		  974: {
			slidesPerView: 2.4,
		  },
		  1200: {
			slidesPerView: 3.2,
		  },
		  1340: {
			slidesPerView: 3.4,
		  },
			1400: {
			slidesPerView: 3.5,
			slidesOffsetAfter: 0,
			}

	},
	on: {
		init: function () {
		  // Get the number of slides
		  var numSlides = this.slides.length;
	
		  // Calculate the value for slidesOffsetAfter
		  var slidesOffsetAfter = (document.querySelector('.recomended-product__swiper .swiper-slide').offsetWidth / 2);

		  if(numSlides <= 3){
			slidesOffsetAfter = 0;
		  }
	
		  // Set the slidesOffsetAfter option
		  this.params.slidesOffsetAfter = slidesOffsetAfter;
		  this.update();
		}
	  }
} );


let productImageTabs;

if(document.querySelector('.product-slider-thumbs')){
  function initializeSwiper() {
	if (window.innerWidth < 1350) {
		 productImageTabs = new Swiper('.product-slider-thumbs', {
			direction: 'horizontal',
			slideToClickedSlide: true,
			slidesPerView: 3,
			initialSlide: 1,
		
			loop:false,
			on: {
				click: function () {
				  const clickedIndex = this.clickedIndex; // index of the clicked slide
				  const activeIndex = this.activeIndex; // index of the currently active slide
				  if (clickedIndex <= activeIndex) {
					this.slidePrev(); // move the slider to the next slide
				  } else{
					this.slideNext(); // move the slider to the previous slide
				  }
				}
			},
		  });
	} else {
		 productImageTabs = new Swiper('.product-slider-thumbs', {
			direction: 'vertical',
			slideToClickedSlide: true,
			slidesPerView: 3,
			initialSlide: 1,
		
			loop:false,
			on: {
				click: function () {
				  const clickedIndex = this.clickedIndex; // index of the clicked slide
				  const activeIndex = this.activeIndex; // index of the currently active slide
				  if (clickedIndex <= activeIndex) {
					this.slidePrev(); // move the slider to the next slide
				  } else{
					this.slideNext(); // move the slider to the previous slide
				  }
				}
			},
		  });
	}
  }
  
  initializeSwiper();
  

  window.addEventListener('resize', function() {
	if (window.innerWidth < 1350 ) {
		productImageTabs.destroy(true, true); // Destroy Swiper instance including all events
		productImageTabs = null;
		productImageTabs = new Swiper('.product-slider-thumbs', {
			direction: 'horizontal',
			slideToClickedSlide: true,
			slidesPerView: 3,
			initialSlide: 1,
		
			loop:false,
			on: {
				click: function () {
				  const clickedIndex = this.clickedIndex; // index of the clicked slide
				  const activeIndex = this.activeIndex; // index of the currently active slide
				  if (clickedIndex <= activeIndex) {
					this.slidePrev(); // move the slider to the next slide
				  } else{
					this.slideNext(); // move the slider to the previous slide
				  }
				}
			},
		  });
	} else if (window.innerWidth >= 1350) {
		productImageTabs.destroy(true, true); // Destroy Swiper instance including all events
		productImageTabs = null;
		productImageTabs = new Swiper('.product-slider-thumbs', {
			direction: 'vertical',
			slideToClickedSlide: true,
			slidesPerView: 3,
			initialSlide: 1,
		
			loop:false,
			on: {
				click: function () {
				  const clickedIndex = this.clickedIndex; // index of the clicked slide
				  const activeIndex = this.activeIndex; // index of the currently active slide
				  if (clickedIndex <= activeIndex) {
					this.slidePrev(); // move the slider to the next slide
				  } else{
					this.slideNext(); // move the slider to the previous slide
				  }
				}
			},
		  });
	}
  });
}
  

  let productImageTop = new Swiper('.product-slider-top', {
//   loopedSlides: 1,
  spaceBetween: 0,
  slidesPerView: 1,
//   centeredSlides: true,
//   allowTouchMove:false,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },

  // USING THE THUMBS COMPONENT
  thumbs: {
    swiper: productImageTabs
  }
  });
  // Add an event listener for the slideChange event
productImageTop.on('slideChange', () => {
	// Get the currently active slide index
	const activeSlideIndex = productImageTop.activeIndex;
  
	// Get the slide element using the activeSlideIndex
	const activeSlideElement = productImageTop.slides[activeSlideIndex];

	// Check if the active slide contains the video class
	const containsVideoClass = activeSlideElement.querySelector('.videos__video');
	
	// Get the Swiper container element
	const swiperContainer = productImageTop.el;
  
	// Add or remove the desired class based on the presence of the video class
	if (containsVideoClass) {
	
	  swiperContainer.classList.add('video-slide');
	} else {
	  swiperContainer.classList.remove('video-slide');
	}
  });
  if(document.querySelector('.product-slider-top')){
  window.addEventListener('resize', function() {
	if (window.innerWidth < 1350 ) {
		productImageTop.destroy(true, true); // Destroy Swiper instance including all events
		productImageTop = null;
		productImageTop = new Swiper('.product-slider-top', {
			//   loopedSlides: 1,
			  spaceBetween: 0,
			  slidesPerView: 1,
			//   centeredSlides: true,
			//   allowTouchMove:false,
			  effect: 'fade',
			  fadeEffect: {
				crossFade: true
			  },
			
			  // USING THE THUMBS COMPONENT
			  thumbs: {
				swiper: productImageTabs
			  }
			  });

	} else if (window.innerWidth >= 1350) {
		productImageTop.destroy(true, true); // Destroy Swiper instance including all events
		productImageTop = null;
		productImageTop = new Swiper('.product-slider-top', {
			//   loopedSlides: 1,
			  spaceBetween: 0,
			  slidesPerView: 1,
			//   centeredSlides: true,
			//   allowTouchMove:false,
			  effect: 'fade',
			  fadeEffect: {
				crossFade: true
			  },
			
			  // USING THE THUMBS COMPONENT
			  thumbs: {
				swiper: productImageTabs
			  }
			  });
	}
  });
}